.faqTitle {
    font-size: 2rem;
    font-family: helveticaNeue;
    color: black;
    font-weight: bold;
}

.questionTitle {
    font-family: helveticaNeue;
    color: black;
    font-size: 1.5rem;
}

.questionAnswer {
    font-weight: lighter;
}