

.carBrand {
    color: #002278;
    font-size: 12px;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
}

.carModel {
    color: #002278;
    font-size: 25px;
    margin: 0;
    font-weight: lighter;
}

.carStatus {
    color: #006828;
    font-size: 10px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    white-space: nowrap;
}

.carStatusSpan {
    font-weight: bold;
}

.discountTitle, .fipeTitle, .investTitle, .exchangerNameTitle {
    margin: 0;
    color: #006828;
    text-align: left;
    text-transform: uppercase;
    font-size: 10px;
    
}

.totalTitle {
    margin: 0;
    color: #002278;
    text-align: left;
    text-transform: uppercase;
    font-size: 10px;
}

.totalValue {
    margin: 0;
    color: #002278;
    text-align: left;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.fipeValue, .investValue, .discountValue  {
    margin: 0;
    color: #006828;
    text-align: left;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
}

.exchangerNameContent {
    margin: 0;
    color: #006828;
    text-align: left;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
}

.salesman {
    margin: 0;
    color: #002278;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 16px;
}

.salesmanName {
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
}

.detailsButton {
    background-color: #006828;
    text-align: center;
    height: 5vh;
}

.removeButton {
    height: 5vh;
}

.seeDetailsButton {
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    margin: 0;
    
}

.seeDetailsButton:hover {
    text-decoration: none;
    color: #ffffff;
}

.linkDetails {
    text-decoration: none;
}

.linkDetails:hover {
    text-decoration: none;
}


.detailsButton:hover {
    background: transparent linear-gradient(180deg, #006828 0%, #3db36a 100%) 0% 0% no-repeat padding-box;
}

.buttonDeleteProduct{
    background: #FF0000 0% 0% no-repeat padding-box;
    text-align: center;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 1;
    border: none;
}

.seeDetailsProduct {
    text-decoration: none;
    color: #FFFFFF;
}

.buttonSeeDetailsProduct:hover {
    background: transparent linear-gradient(180deg, #006828 0%, #3db36a 100%) 0% 0% no-repeat padding-box;
}

.detailsButton:hover {
    cursor: pointer;
}

.linkDetails {
    text-decoration: none;
}

.buttonDeleteProduct:hover {
    cursor: pointer;
    background: transparent linear-gradient(180deg,  #FF0000 0%,  rgb(240, 78, 78) 100%) 0% 0% 
    no-repeat padding-box;
}

.vehicleLaudo {
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    color: #002278;
}

.vehicleHistory {
    color: #002278;
    font-size: 20px;
}



.fontNone {
    color: #002278;
    font-style: 'Roboto';
    font-size: 30px;
    font-weight: bold;
}

.infoNone {
    color: #002278;
    font-style: 'Roboto';
    font-size: 20px;
}
