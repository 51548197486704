.detailsTitle {
    font-size: 30px;
    font-family: 'Prompt', sans-serif;
    color: #ffffff;
    font-weight: bold;
    margin-left: 150px;
}

.titleSettings {
    height: 76px;
    width: 100%;
}

.goBack {
    background-color: #006828;
    height: 76px;
    color: #FFFFFF;
}

.iconGoBack {
    font-size: 26px;
    margin-right: 20px;
}

.greenBgMathes, .redBgMatches {
    height: 76px;
}

.greenBgMatches {
    background-color: #006828;
}

.redBgMatches {
    background-color: #FF0000;
}

.accept, .remove {
    font-weight: bold;
    color: #ffffff;
}

.goBackLink {
    text-decoration: none;
    color: #ffffff;
}

.goBackLink:hover {
    text-decoration: none;
    color: #ffffff;
}

.returnText {
    margin: 0;
}

.button-green{
    background: #006828 0% 0% no-repeat padding-box;
    text-align: center;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000066;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 1;
}
.button-red{
    background: #FF0000 0% 0% no-repeat padding-box;
    text-align: center;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    color: #FFFFFF;
    text-shadow: 0px 3px 6px #00000066;
    text-transform: uppercase;
    text-decoration: none;
    opacity: 1;
}
.purchase-decision{
    height: 49px;
    background: #07518F 0% 0% no-repeat padding-box;
    opacity: 1;
}
.purchase-decision-accept{
    height: 49px;
    background: #006828 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
}
.purchase-decision-accept h3{
    margin-top: 13px;
    text-align: center;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    color: #07518F;
}

.purchase-decision-accept p{
    text-align: center;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 1px;
    color: rgb(112, 118, 122);
    margin: 15px;
}


.purchase-decision-cancel{
    height: 49px;
    background: #FF0000 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
}
.purchase-decision-cancel h3{
    margin-top: 13px;
    text-align: center;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0;
    color: #07518F;
}

.purchase-decision-cancel p{
    text-align: center;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 1px;
    color: rgb(112, 118, 122);
    margin: 15px;
}
.btn-accept{
    background: #006828 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    border: none;
    margin-top: 10px;
    margin-left: 45px;
}
.btn-accept a{
    color: white;
    text-decoration: none;
}
.btn-cancel{
    background: #FF0000 0% 0% no-repeat padding-box;
    opacity: 1;
    color: white;
    border: none;
    margin-top: 10px;
    margin-left: 45px;
}
.btn-cancel a{
    color: white;
    text-decoration: none;
}
.btn-back{
    background: #07518F 0% 0% no-repeat padding-box;
    opacity: 1;
    font: 15px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;    
}
.btn-back a{
    color: white;
    text-decoration: none;
}

.buttonModalAccept {
    margin: 0;
    margin-left: 30px;
}

.buttonModalCancel {
    margin: 0;
}
