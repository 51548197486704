header#buyHeader {
  background-color: #002278;
  border-bottom: 10px solid #068041;
}

header#buyHeader nav {
  position: relative;
  background-color: #002278;
}

header {
  font-size: 18px;
  font-weight: bold;
}

#buyHeader .navbar-brand {
  width: 120px;
}

@media (max-width: 700px) {
  #buyHeader .navbar-brand {
    width: 90px;
  }
}
