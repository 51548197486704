.blueBgMatches {
    background-color: #002278;
}

.titleSettings {
    height: 76px;
    width: 100%;
}

.suggestionsTitle {
    font-size: 30px;
    font-family: 'Prompt', sans-serif;
    color: #ffffff;
    font-weight: bold;
    margin-left: 150px;
}

.myVehicle {
    margin-top: 100px;
}
