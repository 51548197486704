.carouselPosition {
    position: relative;
}

.ribbon1 {
    position: absolute;
    top: -6.1px;
    right: 10px;
    z-index: 99;
    color: #FFFFFF;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .ribbon1:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 53px solid transparent;
    border-right: 37px solid transparent;
    border-top: 10px solid #07518F;
  }
  .ribbon1 span {
    position: relative;
    display: block;
    text-align: center;
    background: #07518F;
    font-size: 14px;
    line-height: 1;
    padding: 12px 8px 10px;
    border-top-right-radius: 8px;
    width: 90px;
  }
  .ribbon1 span:before, .ribbon1 span:after {
    position: absolute;
    content: "";
  }
  .ribbon1 span:before {
   height: 6px;
   width: 6px;
   left: -6px;
   top: 0;
   background: #07518F;
  }
  .ribbon1 span:after {
   height: 6px;
   width: 8px;
   left: -8px;
   top: 0;
   border-radius: 8px 8px 0 0;
   background: rgb(12, 97, 167);
  }

  .carouselLarge {
    max-width: 780px;
    height: 395px;
    max-height: 395px;
    margin: auto;
  }

  .carouselMedium {
   
    max-width: 470px;
    height: 250px;
    max-height: 250px;
    margin: auto;
  }

  .carouselSmall {
    max-width: 300px;
    height: 150px;
    max-height: 225px;
    margin: auto;
  }



  .carousel-control-next-icon {
    background-image: none;
  }

  .carousel-control-prev-icon {
    background-image: none;
  }

  .angleSettings {
    font-size: 30px;
    color: blue;
  }