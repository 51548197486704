.loginBg {
    background: transparent linear-gradient(180deg,#002278,#001343 80%,#00040e) 0 0 no-repeat padding-box;
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.loginCard {
    background-color: #f7fafc !important;
    width: 30rem;
    border-radius: 3px;
    border: 1px solid rgb(218, 140, 7);
}