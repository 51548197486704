@font-face {
    font-family: helveticaNeue;
    src: url(../../assets/fonts/helvetica-neue-67-medium-condensed.otf);
}

.partnerTitle { 
    font-family: helveticaNeue;
    font-size: 2rem;
}

.partnerDesc {
    font-family: helveticaNeue;
    font-size: 1.5rem;
}