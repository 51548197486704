.errorCode {
    font-size: 100px;
    font-weight: bold;
    color: #002278;
    text-transform: uppercase;
}

.errorMessage {
    font-size: 20px;
    font-weight: bold;
    color: #002278;
    text-transform: uppercase;
}

.linkRedirectError {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.linkRedirectError:hover {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    
}

.buttonErrorBackground {
    background-color: #002278;
    border-radius: 5px;
    width: 300px;
    height: 4vh;
}

.buttonErrorBackground:hover {
    background: transparent linear-gradient(180deg, #002278 0%, #0149ff 100%) 0% 0% no-repeat padding-box;
}

.errorSettings {
    color: #002278;
    font-weight: bold;
    font-size: 60px;
}
