@font-face {
    font-family: helveticaNeue;
    src: url(../../assets/fonts/helvetica-neue-67-medium-condensed.otf);
}

.teamTitle { 
    font-family: helveticaNeue;
    font-size: 2rem;
}

.ceoIcon {
    font-size: 7rem;
    color: rgb(19, 122, 173)
}

.ceoName {
    font-family: helveticaNeue;
    font-size: 2rem;
}

.ceoSubtitle {
    font-family: helveticaNeue;
    font-size: 1.2rem;
    color: rgb(19, 122, 173)
}

.ceoDesc {
    font-family: helveticaNeue;
    font-weight: lighter;
}

.teamIcon {
    font-size: 2rem;
    color: rgb(19, 122, 173)
}

.teamMateTitle {
    color: rgb(19, 122, 173);
    font-family: helveticaNeue;
    font-size: 1.2rem;
}

.teamDesc {
    font-family: helveticaNeue;
    font-weight: lighter;
}

@media (min-width: 1200px) {
    .teamCard {
        width: 338px;
        height: 236px;
    }
}
