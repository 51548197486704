.blueButtonBg {
    background: transparent linear-gradient(180deg, #002278 0%, #001446 50%) 0% 0% no-repeat padding-box;
}

.blueButtonBg:hover {
    background: transparent linear-gradient(180deg, #0c2c7a 0%, #00040e 50%) 0% 0% no-repeat padding-box;
}

.buttonSettings {
    border-radius: 5px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    width: 120px;
    height: 30px;
    font-family: 'Prompt', sans-serif;
    cursor: pointer;
}

.buttonContent {
    margin-top: 5px;
}

.barSettings {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: lighter;
}
