.yellowBg {
  background-color: #f9a000;
}

.headerSettings {
  height: 17.7vh;
  width: 100%;
  position: relative;
}

.logoSettings {
  width: 190px;
  position: absolute;
  top: 10px;
  left: 150px;
  z-index: 99;
}

.userInput,
.passwordInput {
  width: 294px;
  font-size: 5px;
  font-family: "Prompt", sans-serif;
}

#standard-basic {
  color: #ffffff;
}

.rowLoginSettings {
  top: 70px;
  right: 150px;
}

.forgotPassword {
  color: #ffffff;
  white-space: nowrap;
  text-decoration: none;
}

.forgotPassword:hover {
  text-decoration: none;
  color: #ffffff;
}

.blueButtonBg {
  background: transparent linear-gradient(180deg, #002278 0%, #001446 50%) 0% 0%
    no-repeat padding-box;
}

.blueButtonBg:hover {
  background: transparent linear-gradient(180deg, #0c2c7a 0%, #00040e 50%) 0% 0%
    no-repeat padding-box;
}

.buttonSettings {
  border-radius: 5px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  width: 120px;
  height: 30px;
  font-family: "Prompt", sans-serif;
}

.buttonContent {
  margin-top: 5px;
}

.barSettings {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: lighter;
}

@media (max-width: 1000px) {
  .rowLoginSettings {
    top: 120px;
  }

  .headerSettings {
    height: 200px;
    width: 100%;
    position: relative;
  }

  .buttonSettings {
    border-radius: 5px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    width: 100%;
    font-family: "Prompt", sans-serif;
    margin-bottom: 10px;
  }

  .logoDiv {
    display: none;
  }

  .inputsContainer {
    height: 60px;
  }
}
