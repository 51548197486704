.head-table-2-blue{
    padding: 0;
    overflow-x: hidden;
    color: #002278;
}
.top .head-table-2-green{
    padding: 0;
    overflow-x: hidden;
    color: #408E5E;
}


.info-line-1{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1;
}
.info-line-2{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 25px;
    font-weight: lighter;
    letter-spacing: 0;
    line-height: 30px;
    opacity: 1;
    min-height: 60px;
    border-bottom: solid 1px rgba(	0, 104, 40, 0.2);
}

.border-line-3, .border-line-4, .border-line-7, .border-line-8, .border-line-9 {
    border-bottom: solid 1px rgba(	0, 104, 40, 0.2);
}

.border-right-line {
    border-right: solid 1px rgba(	0, 104, 40, 0.2);
}
.info-line-3{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1.5; 
}
.info-line-4 {
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 26px;
    text-transform: uppercase;
    opacity: 1;
}
.info-line-5{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: 1;
}
.info-line-7{
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: bold;  
    opacity: 1;
}

.other_info{
    direction: rtl; 
    overflow-y:scroll; 
    height:80px; 
    margin:0;
}

.overflowSettings {
    max-height: 38px;
    overflow-y: auto;
    min-height: 54px;
    max-width: 100%;
    min-width: 490px;
}

.thirdLineSettings {
    height: 60px;
}
