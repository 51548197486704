#order-list {
  height: 50vh;
}

#order-list::-webkit-scrollbar {
  width: 12px;
  height: 20px;
  padding: 10px;
}

#order-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #068041;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
#order-list::-webkit-scrollbar:hover {
  background: #6c757d38;
}
