.helloExchanger {
    font-size: 24px;
    font-family: 'Prompt', sans-serif;
    color: #F9A000;
}

.descriptionExchanger {
    color: #ffffff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 15px;
}