.blueBgRegister {
    background: transparent linear-gradient(180deg, #002278 0%, #001343 80%, #00040E 100%) 0% 0% no-repeat padding-box;
}

.bgDivSettings {
    margin: 0;
    padding: 0;
}

.MuiFormLabel-root {
    color: #ffffff!important;
    font-size: 13px!important;
}

.MuiInput-underline:before {
    border-bottom: 1px solid rgb(255, 255, 255)!important;
}

.questionRegister {
    color: #F9A000;
    font-size: 20px;
    text-align: left;
    font-family: 'Prompt', sans-serif;
}

.guideRegister, .recoverVehicleText {
    color: #ffffff;
    font-family: 'Prompt', sans-serif;
    font-size: 14px;
}

.materialIconColor {
    color: white;
    font-size: 60px;
}

