.bgYellowMenu {
    background-color: #F9A000;
}

.menuSettings {
    width: 100%;
    height: 116px;
}

.logoSettings {
    width: 190px;
    position: absolute;
    top: 10px;
    left: 150px;
    z-index: 99;
}

.exchangerName {
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
}

.exchangerPhoto {
    border-radius: 50%;
    background-color: #ffffff;
    width: 51px;
    height: 51px;
    box-shadow: 0px 3px 6px #00000029;
}

.userInitials {
    color: #002278;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20%;
}

.menuBreak {
    width: 34.3%;
    background-color: #ffffff;
    margin: 0;
    margin-top: 5px;
    margin-left: 200px;
}

.editProfile, .seeCoins, .accessPublish, .seeFilters, .seeMenu {
    color: #ffffff;
    text-transform: uppercase;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
}

.coins, .publish, .filters, .menu {
    border-radius: 50%;
    background-color: #ffffff;
    width: 57px;
    height: 57px;
    margin-left: 5%;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
}

.coinsSettings {
    color: #006828;
    font-size: 30px;
    font-weight: bold;
    margin-top: 5%;
}

.publishSettings, .filtersSettings, .menuIconSettings {
    font-size: 20px;
    margin-top: 30%;
}

.publishSettings {
    color: #006828;
}

.filtersSettings {
    color: #F9A000;
}

.menuIconSettings {
    color: #002278;
}

.titlesList {
    list-style: none;
    display: flex;
    margin-right: 130px;
}

.seeMenu {
    margin-left: 30px;
    margin-right: 30px;
}

.seeFilters {
    margin-left: 20px;
}

.accessPublish {
    margin-left: 10px;
}

.seeCoins {
    margin-left: 180px;
}

@media (max-width: 1000px) {
    .menuDisplay {
        display: none;
    }

    .logoDisplay { 
        display: none;
    }

    .bgYellowMenu {
        background-color: #F9A000;
        height: 200px;
    }

    .menuBreak {
        display: none!important;
    }

    .seeMenu {
        margin-left: 85%; 
        
    }
    
    .seeFilters {
        margin-left: 30%;
        
       
    }
    
    .accessPublish {
        margin-left: 5%;
        
    }
    
    .seeCoins {
        margin-left: 5%;
        
    }

    
}