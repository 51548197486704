.skeleton-card {
  width: 100%;
  min-height: 170px;
  position: relative;
}
.skeleton-card-header {
  background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
}
.skeleton-card-body {
  height: 100%;
}
.skeleton-text {
  width: 80%;
  margin: 20px auto;
  height: 10px;
  background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
}

.skeleton-card-footer {
  position: absolute;
  bottom: 0;
  background: linear-gradient(-90deg, #e7edf1 0%, #f8f8f8 50%, #e7edf1 100%);
  height: 20px;
  background-size: 400% 400%;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  animation: shimmer 1.2s ease-in-out infinite;
}

@keyframes shimmer {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}
