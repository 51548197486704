.nameInput, .mailInput, .whatsInput, .usernameInput, .userPassInput {
    width: 100%;
}

.registerInputs .MuiFormLabel-root {
    color: #F9A000!important;
    font-size: 13px!important;
}

.submitRegister {
    background: transparent linear-gradient(180deg, #006828 0%, #003414 100%) 0% 0% no-repeat padding-box;
    border: none;
    box-shadow: 0px 3px 6px #00000066;
    border-radius: 5px;
    height: 36px;
    width: 100%;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    font-family: 'Prompt', sans-serif;
    font-size: 13px;
}

.submitRegister:hover {
    background: transparent linear-gradient(180deg, #01421a 0%, #00200d 100%) 0% 0% no-repeat padding-box;
}

.whatIsRegister, .howItWorks, .searchFilters {
    font-weight: bold;
    color: #F9A000;
    font-size: 20px;
    text-align: left;
    font-family: 'Prompt', sans-serif;
}

.whatIsText, .whatIsContinue, .howItWorksText, .searchFiltersText {
    color: #002278;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

.goodExchanges {
    color: #002278;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Prompt', sans-serif;
}

@media (max-width: 1300px) {
    .homeVideo { 
        width: 100%;
        height: 200px;
    }
}

@media (min-width: 1301px) {
    .homeVideo { 
        width: 100%;
        height: 400px;
    }
}
