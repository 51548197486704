@font-face {
    font-family: helveticaNeue;
    src: url(../../assets/fonts/helvetica-neue-67-medium-condensed.otf);
  }

.imgSet {
    width: 7rem;
}

.linkSet {
    font-weight: 800;
    font-size: 1.3rem;
    font-family: helveticaNeue;
    color: black!important;
}

.linkSet:hover {
    border-bottom: solid rgb(19, 122, 173) 1px;
}

.causeBanner {
    background-image: url(../../assets/img/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 86vh;
    background-position-y: 65%;
}



.causeTitle {
    font-size: 1.5rem;
    font-weight: bolder;
    color: black;
    font-family: helveticaNeue;
}

.causeQuestion {
    font-size: 2.5rem;
    font-weight: bolder;
    color: black;
    font-family: helveticaNeue;
}

.causeDesc {
    font-size: 2rem;
    font-family: helveticaNeue;
}

.videoSub {
    color: #0387df;
    margin-bottom: 0!important;
    text-transform: uppercase;
    font-family: helveticaNeue;
}

.videoTitle {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0!important;
    font-family: helveticaNeue;
}

.videoDesc {
    font-family: helveticaNeue;
    font-size: 1.5rem;
}

.productTitle {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0;
    font-family: helveticaNeue;
}

.downloadApp {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-top: 0;
    text-transform: uppercase;
    color: #002278;
    font-family: helveticaNeue;
}

.iconSet { 
    font-size: 2.5rem;
}

.contactTitle {
    font-size: 2.5rem;
    font-weight: bolder;
    color: black;
    font-family: helveticaNeue;

}

.reasonsTitle {
    font-size: 2.5rem;
    font-weight: bolder;
    color: #ffffff;
    font-family: helveticaNeue;

}

.contactDiv {
    background-color: #ffffff;
}

.reasonsDiv {
    background-color: rgb(19, 122, 173);
}

.downloadDiv {
    background-color: rgb(19, 122, 173)
}

.downloadText {
    color: #ffffff;
    font-size: 1.5rem;
}

.companyTitle {
    font-weight: bold;
    font-size: 1.2rem;
    font-family: helveticaNeue;

}

.redirectButton {
    background-color: rgb(19, 122, 173);
    width: 15rem;
    height: 3rem;
}

.redirectButton p {
    color:  rgb(222, 231, 240);
    text-transform: uppercase;
    font-family: helveticaNeue;

}

.redirectButton:hover {
    background-color: rgb(5, 84, 124);
    text-decoration: none;
}

.footerBg {
    background-color: black;
}

.reasonsIcon {
    font-size: 2.5rem;
    color: rgb(5, 131, 102);
}

.reasonCard {
    background-color: #ffffff;
    border-radius: 10px;
    width: 25rem;
}

.reasonTitle {
    font-weight: 700;
    color: rgb(5, 131, 102);
    font-family: helveticaNeue;
}

.reasonDesc {
    text-align: justify;
    font-family: helveticaNeue;
}

.footerSectionTitle {
    color: rgb(218, 140, 7);
    font-size: 1.2rem;
    font-family: helveticaNeue;

}

.footerText {
    color: #ffffff;
    font-family: helveticaNeue;

}

.lineBorder {
    border-top: 1px solid rgb(255, 255, 255);
}

.socialIcon { 
    font-size: 2.3rem;
    color: rgb(218, 140, 7);
}

.copyText {
    color: #ffffff;
    font-family: helveticaNeue;

}

.loginMenuSet {
    background-color: rgb(218, 140, 7);
    width: 5rem;
}

.header {
    background-color: white;
}

.bannerTitle {
    color: white;
    font-weight: bold;
    font-family: helveticaNeue;
    font-size: 3.5rem;
}

.bannerSubTitle {
    color: white;
    font-family: helveticaNeue;
    font-weight: bold;
    font-size: 3rem;
}

.bannerText {
    font-family: helveticaNeue;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2rem;
    color: white;
}

.btnLogin {
    width: 10rem;
    background-color: rgb(19, 122, 173);
    color: #ffffff!important;
    font-weight: 800;
    font-size: 1.3rem;
    font-family: helveticaNeue;
    font-weight: 500;
}

.btnLogin:hover {
    background-color: rgb(5, 84, 124);
}

.callToButton {
    background-color: rgb(1, 253, 55);
    width: 15rem;
    color: black;
    text-transform: uppercase;
}

.callToButton:hover {
    background-color: rgb(3, 170, 39);
    color: white;
    text-decoration: none;
}

.linkButton {
    display: block;
    position: absolute;
    width: 14%;
    height: 9%;
    margin-block-start: 42%;
    color: transparent;
}



@media (min-width: 900px) {
    .borderHeader {
        border-bottom: solid 1px black;
        width: 100%;
    }
}

@media (max-width: 900px) {
    .causeBanner {
        background-image: url(../../assets/img/banner.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        background-position-x: 50%;
    }

    .bannerTitle {
        color: white;
        font-weight: bold;
        font-family: helveticaNeue;
        font-size: 2rem;
    }

    .bannerSubTitle {
        color: white;
        font-family: helveticaNeue;
        font-weight: bold;
        font-size: 1.5rem;
    }
    
    .bannerText {
        font-family: helveticaNeue;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1rem;
        color: white;
    }

    .callToButton {
        background-color: rgb(1, 253, 55);
        width: 15rem;
        color: black;
        text-transform: uppercase;
    }
    
}




