.userProfileImageMatches {
    border: solid 5px #006828;
    border-radius: 50%;
    width: 15%;
    height: 25vh;
}

.userNameMatches {
    color: #006828;
    font-size: 28px;
    font-weight: bold;
    margin: 0;
}

.userCpfMatches {
    font-size: 15px;
    color: #006828;
    margin: 0;
}

.userMailMatches {
    font-size: 15px;
    color: #006828;
    margin: 0;
}

.userPhoneMatches {
    font-size: 15px;
    color: #006828;
    margin: 0;
}

.userTimeMatches {
    color: #006828;
    font-weight: bold;
    margin: 0;
}

.userProfileInfoMatches {
    margin-left: 40px;
}