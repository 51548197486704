@font-face {
    font-family: helveticaNeue;
    src: url(../../assets/fonts/helvetica-neue-67-medium-condensed.otf);
}

.contactPageBanner {
    height: 20vh;
    background-image: url(../../assets/img/contact.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 50%;
    background-position-y: -250px;
}

.contactPageTitle {
    font-size: 2rem;
    font-family: helveticaNeue;
    color: black;
    font-weight: bold;
}