.blogTitle {
    font-family: helveticaNeue;
    font-size: 2rem;
}

.btnRead {
    width: 10rem;
    background-color: rgb(19, 122, 173);
    color: #ffffff!important;
    font-weight: 800;
    font-size: 1rem;
    font-family: helveticaNeue;
    font-weight: 500;
}

.cardtextSet {
    font-family: helveticaNeue;
}

.cardTitleSet {
    font-family: helveticaNeue;
    font-size: 1.5rem;
}